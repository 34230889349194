import { createTheme } from '@mui/material/styles';
import {
  NunitoSansRegular,
  NunitoSansSemiBold,
  NunitoSansBold
} from 'themes/nunito-sans-font';

// import { PaletteMode } from '@mui/material';
import React from 'react';

declare module '@mui/material/styles' {
  // index signature typegradients

  interface TypeGradient {
    [key: string]: string;
  }

  interface Palette {
    gradient: TypeGradient;
    text: TypeText;
  }

  interface TypeText {
    primary: string;
    secondary: string;
    contrastText: string;
    disabled: string;
    shadedText: string;
  }

  interface TypeColor {
    Darkest?: string;
    Darker?: string;
    Dark?: string;
    Base?: string;
    Light?: string;
    Lighter?: string;
    Lightest?: string;
    White?: string;
  }

  interface PaletteOptions {
    gradient?: TypeGradient;
    // Ink: TypeColor;
    // Sky: TypeColor;
    // Red: TypeColor;
    // Green: TypeColor;
  }

  interface TypeBackground {
    opposite: string;
    card: string;
    overlayBanner: string;
    banner: string;
    secondary: string;
    overlayBanner2: string;
    yellow: string;
    orange: string;
  }
  interface TypographyVariants {
    CTA1: React.CSSProperties;
    CTA2: React.CSSProperties;
    CTA3: React.CSSProperties;
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    Body1: React.CSSProperties;
    Body2: React.CSSProperties;
    Body3: React.CSSProperties;
    Body1Medium: React.CSSProperties;
    Body1SemiBold: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    CTA1?: React.CSSProperties;
    CTA2?: React.CSSProperties;
    CTA3?: React.CSSProperties;
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
    Body1?: React.CSSProperties;
    Body2?: React.CSSProperties;
    Body3?: React.CSSProperties;
    Body1Medium?: React.CSSProperties;
    Body1SemiBold?: React.CSSProperties;
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    CTA1: true;
    CTA2: true;
    CTA3: true;
    body3: true;
    body4: true;
    Body1: true;
    Body2: true;
    Body3: true;
    Body1Medium: true;
    Body1SemiBold: true;
  }
}

function AppThemeProvider(oemName: string) {
  const commonTheme = {
    typography: {
      fontFamily: ['Nunito Sans', 'sans-serif'].join(','),
      fontSize: 16,
      fontWeightBold: 600,

      h1: {
        fontSize: '32px',
        fontWeight: 500,
        lineHeight: '38px'
      },
      h2: {
        fontSize: '22px',
        fontWeight: 600,
        lineHeight: '26px'
      },
      h3: {
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '20.5px'
      },
      h4: {
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '18.45px'
      },
      h5: {
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '24px'
      },
      h6: {
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '16.4px'
      },

      CTA1: {
        fontSize: '8.97px',
        fontWeight: 500,
        lineHeight: '9.19px'
      },
      CTA2: {
        fontSize: '8.76px',
        fontWeight: 400,
        lineHeight: '11.26px'
      },
      Body1: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '18px'
      },
      Body2: {
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '12.83px'
      },
      Body3: {
        fontSize: '10px',
        fontWeight: 600,
        lineHeight: '16px'
      },

      Body1Medium: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '18px'
      },
      Body1SemiBold: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '14.35px'
      }
    }
  };

  const instavisionCoreTheme = createTheme(
    {
      palette: {
        primary: {
          main: '#4389E1'
        },
        secondary: {
          main: '#FF6F61'
        },

        background: {
          default: '#F1F1F1',
          opposite: '#333333',
          paper: '#F1F1F1',
          card: '#EDEDED',
          banner: '#6171ff',
          overlayBanner: '#6171ff',
          secondary: '#5fa4fb',
          overlayBanner2: '#0faf62',
          yellow: '#F3B71D',
          orange: '#F35D1D'
        },
        text: {
          primary: '#333333',
          secondary: '#000000',
          contrastText: '#ffffff',
          disabled: '#C3C1BD',
          shadedText: '#000000'
        },
        gradient: {
          green:
            'linear-gradient(266.79deg, rgba(67, 137, 225, 0.8) 2.66%, rgba(3, 203, 106, 0.8) 85.83%)'
        },
        action: {
          hover: '#F0F4F4'
        }
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            body: {
              fontFamily: `${NunitoSansRegular}, ${NunitoSansSemiBold}, ${NunitoSansBold}`
            }
          }
        },
        MuiLink: {
          styleOverrides: {
            root: {
              cursor: 'pointer',
              textDecoration: 'none',
              lineHeight: '16px',
              transition: 'all 0.1s ease-in-out',
              '&:hover': {
                opacity: 0.8
              }
            }
          }
        },
        MuiIconButton: {
          styleOverrides: {
            root: {
              aspectRatio: '1/1'
            }
          }
        },
        MuiButton: {
          styleOverrides: {
            root: ({ ownerState }) => ({
              ...(ownerState.variant === 'contained' && {
                padding: instavisionCoreTheme.spacing(2.5),
                fontSize: instavisionCoreTheme.typography.h3.fontSize,
                fontWeight: instavisionCoreTheme.typography.h3.fontWeight,
                lineHeight: instavisionCoreTheme.typography.h3.lineHeight,
                textTransform: 'capitalize',
                background:
                  'linear-gradient(135deg, #E4E4E4 100%, #F5F5F5 100%)',
                borderStyle: 'solid !important',
                borderWidth: '1px !important',
                borderRadius: '2rem',
                borderImageSource:
                  'linear-gradient(315deg, #D5D5D5 0%, #FFFFFF 100%)',
                boxShadow: '2px 2px 10px -4px rgba(0, 0, 0, 0.08)',
                color: instavisionCoreTheme.palette.text.primary
                // boxShadow: '3px 3px 6px #b8b9be,-3px -3px 6px #fff'
              })
            })
          }
        },
        MuiTextField: {
          styleOverrides: {
            root: ({ ownerState }) => ({
              ...(ownerState.variant === 'outlined' && {
                width: '100%',
                fontSize: instavisionCoreTheme.typography.h4.fontSize,
                fontWeight: instavisionCoreTheme.typography.h4.fontWeight,
                lineHeight: instavisionCoreTheme.typography.h4.lineHeight,
                background: instavisionCoreTheme.palette.background.card,
                border: '0.5px solid',
                borderRadius: '20px',
                borderImageSource:
                  'linear-gradient(318.14deg, #D5D5D5 9.39%, #FFFFFF 88.07%)',

                // boxShadow: '3px 3px 6px #b8b9be,-3px -3px 6px #fff'

                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none'
                },
                '& input': {
                  padding: instavisionCoreTheme.spacing(2.5),
                  fontSize: instavisionCoreTheme.typography.h4.fontSize,
                  fontWeight: instavisionCoreTheme.typography.h4.fontWeight,
                  lineHeight: instavisionCoreTheme.typography.h4.lineHeight
                }
              })
            })
          }
        }
      }
    },
    commonTheme
  );

  const lunaTheme = createTheme(
    {
      palette: {
        primary: {
          main: '#2C3E50'
        },
        secondary: {
          main: '#FF6F61'
        },

        background: {
          default: '#F1F1F1',
          opposite: '#333333',
          paper: '#F1F1F1',
          card: '#EDEDED',
          banner: '#4389E1',
          overlayBanner: '#4389e1',
          secondary: '#5fa4fb',
          overlayBanner2: '#1abc9c',
          yellow: '#F3B71D'
        },
        text: {
          primary: '#2C3E50',
          secondary: '#000000',
          contrastText: '#ffffff',
          disabled: '#C3C1BD',
          shadedText: '#000000'
        },
        gradient: {
          green:
            'linear-gradient(266.79deg, rgba(67, 137, 225, 0.8) 2.66%, rgba(3, 203, 106, 0.8) 85.83%)'
        },
        action: {
          hover: '#F0F4F4'
        }
      },

      components: {
        MuiCssBaseline: {
          styleOverrides: {
            body: {
              fontFamily: `${NunitoSansRegular}, ${NunitoSansSemiBold}, ${NunitoSansBold}`
            }
          }
        },
        MuiLink: {
          styleOverrides: {
            root: {
              cursor: 'pointer',
              textDecoration: 'none',
              lineHeight: '16px',
              transition: 'all 0.1s ease-in-out',
              '&:hover': {
                opacity: 0.8
              }
            }
          }
        },
        MuiIconButton: {
          styleOverrides: {
            root: {
              aspectRatio: '1/1'
            }
          }
        },
        MuiButton: {
          styleOverrides: {
            root: ({ ownerState }) => ({
              ...(ownerState.variant === 'contained' && {
                padding: lunaTheme.spacing(2.5),
                fontSize: lunaTheme.typography.h3.fontSize,
                fontWeight: lunaTheme.typography.h3.fontWeight,
                lineHeight: lunaTheme.typography.h3.lineHeight,
                textTransform: 'capitalize',
                background:
                  'linear-gradient(135deg, #E4E4E4 100%, #F5F5F5 100%)',
                borderStyle: 'solid !important',
                borderWidth: '1px !important',
                borderRadius: '2rem',
                borderImageSource:
                  'linear-gradient(315deg, #D5D5D5 0%, #FFFFFF 100%)',
                boxShadow: '2px 2px 10px -4px rgba(0, 0, 0, 0.08)',
                color: lunaTheme.palette.text.primary
                // boxShadow: '3px 3px 6px #b8b9be,-3px -3px 6px #fff'
              })
            })
          }
        },
        MuiTextField: {
          styleOverrides: {
            root: ({ ownerState }) => ({
              ...(ownerState.variant === 'outlined' && {
                width: '100%',
                fontSize: lunaTheme.typography.h4.fontSize,
                fontWeight: lunaTheme.typography.h4.fontWeight,
                lineHeight: lunaTheme.typography.h4.lineHeight,
                background: lunaTheme.palette.background.card,
                border: '0.5px solid',
                borderRadius: '20px',
                borderImageSource:
                  'linear-gradient(318.14deg, #D5D5D5 9.39%, #FFFFFF 88.07%)',

                // boxShadow: '3px 3px 6px #b8b9be,-3px -3px 6px #fff'

                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none'
                },
                '& input': {
                  padding: lunaTheme.spacing(2.5),
                  fontSize: lunaTheme.typography.h4.fontSize,
                  fontWeight: lunaTheme.typography.h4.fontWeight,
                  lineHeight: lunaTheme.typography.h4.lineHeight
                }
              })
            })
          }
        }
      }
    },
    commonTheme
  );

  const theme = instavisionCoreTheme;
  const luna = lunaTheme;
  if (oemName === 'luna') {
    return luna;
  }

  return theme;
}

export default AppThemeProvider;

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import CameraView from 'assets/camera-view.png';
import {
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
  Snackbar,
  Alert
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useFormik } from 'formik';
import { TSubscriptionType, TAddCameraRequest } from 'typings';
import { fetchAllSpaces } from 'store/reducers/spaceReducers';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchAllSubscription } from 'store/reducers/subscriptionReducer';
import AddCameraRequestFormValidationSchema from 'views/AddCameraInfo/validation';
import {
  getSubscriptionDetails,
  checkFreeTrialEligibility,
  fetchCurrentPlanDetails
} from 'services/api/subscriptionApi';
import { getAllDeviceBySpaceId } from 'services/api/SpacesApi';
import { updateFreeTrialEligibility } from 'store/reducers/userReducers';

import { endpoints } from 'services/api/endpoints';
import InstaSpinner from 'components/InstaSpinner';

function AddCameraInfo() {
  const defaultValues = {
    name: '',
    location: ''
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeviceDataLoading, setIsDeviceDataLoading] = useState(true);
  const [deviceSnapShotUrl, setDeviceSnapShotUrl] = useState('');
  const [subscriptionData, setSubscriptionData] = useState<any>();
  const [isEligibleForTrial, toggleIsEligibleForTrial] = useState(false);
  const [planLevel, setPlanLevel] = useState<number>(1);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const spaceId = query.get('space_id') || '';
  const deviceId = query.get('device_id') || '';
  // const isEligibleForTrial = useAppSelector(
  //   (state) => state.user.isEligibleForTrial
  // );
  // const subscriptionData = useAppSelector<TSubscriptionType[]>(
  //   (state) => state.subscription.subscriptionData
  // );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (spaceId) {
      setIsDeviceDataLoading(true);
      Promise.all([
        getSubscriptionDetails(),
        getAllDeviceBySpaceId(spaceId),
        checkFreeTrialEligibility()
      ])
        .then((res) => {
          const [subscriptionsData, devicesData, freeTrialEligibility] = res;

          const found = devicesData.data.items.find(
            (deviceItem: { id: string }) => deviceItem.id === deviceId
          );

          console.log('FOUND ', found);
          if (found?.device_state?.snapshot_url) {
            setDeviceSnapShotUrl(found?.device_state?.snapshot_url);
          }
          setSubscriptionData(subscriptionsData.data);

          toggleIsEligibleForTrial(freeTrialEligibility.data.trial);

          const planSkuId = subscriptionsData.data[0]?.sku_id || '';
          fetchCurrentPlanDetails(planSkuId).then((planInformation) => {
            setPlanLevel(planInformation.data.level);
          });

          dispatch(updateFreeTrialEligibility(freeTrialEligibility.data.trial));
          dispatch(fetchAllSpaces());
        })
        .catch((err) => {
          console.log('err', err);
        })
        .finally(() => {
          setIsDeviceDataLoading(false);
        });
    }
  }, []);

  const isAnyActiveNonLinkedSubscription = () =>
    subscriptionData.find(
      (item: any) =>
        item.id &&
        item.sku_id &&
        item.subscribe_status === 'Active' &&
        item.pay_status === 'Success' &&
        item.devices.length === 0
    );

  console.log(
    'isAnyActiveNonLinkedSubscription ',
    isAnyActiveNonLinkedSubscription
  );

  const handleSnackBarCloseButton = () => {
    setShowSnackBar(false);
  };

  const addCameraToSubscription = async (subscriptionObj: any) => {
    const subscriptionId = subscriptionObj.id;
    let payload = {};
    if (planLevel > 1) {
      const deviceArray = subscriptionObj.devices.map((el: any) => el?.id);
      const device_ids = deviceArray;
      device_ids.push(deviceId);

      payload = {
        device_ids
      };
    } else {
      payload = {
        device_ids: [deviceId]
      };
    }
    try {
      const res = await axios.put(endpoints.addDevice(subscriptionId), payload);
      console.log('addCameraToSubscription', res);
      dispatch(fetchAllSpaces());
      dispatch(fetchAllSubscription());
      return true;
    } catch (err: any) {
      console.log('addCameraToSubscription', err);
      setShowSnackBar(true);
      const errorText = `Error: addDevice ${err?.message}. Please try again`;
      setErrorMessage(errorText);
      return false;
    }
  };

  const handleFormSubmit = async (values: TAddCameraRequest) => {
    if (isSubmitting) return;
    try {
      setIsSubmitting(true);

      // first we add camera name (patch)
      const res = await axios.patch(
        endpoints.addCameraToSubscription(spaceId, deviceId),
        {
          ...values,
          pairing_status: 'Activated'
        }
      );
      console.log('add camera name api response -- ', res);

      // then we authenticate camera name (patch)
      // const deviceAuthenticationResponse = await authenticateDevice();
      // console.log(
      //   'authentication of device api response --',
      //   deviceAuthenticationResponse
      // );

      if (subscriptionData?.length === 0) {
        // if no subscription then we show promo screen
        navigate('/promotion', {
          replace: true,
          state: {
            isFreeTrial: isEligibleForTrial,
            isUpgrade: false,
            deviceId
          }
        });
        return;
      }

      const isAnyNonLinkedSubscription = isAnyActiveNonLinkedSubscription();

      if (isAnyNonLinkedSubscription && deviceId) {
        // if existing subscription but no camera is linked to it, then we add camera
        const isCameraAdded = await addCameraToSubscription(
          isAnyNonLinkedSubscription
        );

        if (isCameraAdded) {
          navigate('/singleCameraAdded', {
            state: {
              subscriptionObj: isAnyNonLinkedSubscription
            }
          });
        }
        console.log('isCameraAdded ', isCameraAdded);
        return;
      }

      if (subscriptionData.length > 0 && planLevel > 1) {
        const isCameraAdded = await addCameraToSubscription(
          subscriptionData[0]
        );
        if (isCameraAdded) {
          setIsSubmitting(false);

          navigate('/multiCameraAdded');
        }
        console.log('isCameraAdded ', isCameraAdded);
        return;
      }

      if (subscriptionData.length > 0 && planLevel === 1) {
        navigate('/upgradeBasic', {
          replace: true,
          state: {
            subscriptionObj: subscriptionData[0],
            isFreeTrial: subscriptionData[0].trial,
            deviceId
          }
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: AddCameraRequestFormValidationSchema,
    onSubmit: handleFormSubmit
  });

  console.log('deviceSnapShotUrl - ', deviceSnapShotUrl);

  if (isDeviceDataLoading) {
    return <InstaSpinner withWrapper />;
  }

  if (!isDeviceDataLoading) {
    return (
      <>
        <form onSubmit={formik.handleSubmit}>
          <Stack justifyContent="space-between" alignItems="center" gap={5}>
            <Stack
              justifyContent="flex-start"
              alignItems="center"
              gap={2.5}
              width="100%"
            >
              <img
                src={deviceSnapShotUrl || CameraView}
                alt=""
                style={{ borderRadius: '16px', maxWidth: '90%' }}
              />
              <Typography variant="h2" padding="18px">
                Your camera is Paired
              </Typography>
              <TextField
                id="name"
                variant="outlined"
                placeholder="Name Your Camera"
                required
                value={formik.values.name}
                onChange={formik.handleChange}
                error={Boolean(formik?.errors?.name)}
                helperText={
                  formik?.errors?.name ? 'Camera name is required.' : ''
                }
              />
              <TextField
                id="location"
                variant="outlined"
                placeholder="Location"
                value={formik.values.location}
                onChange={formik.handleChange}
              />
            </Stack>
            <Stack justifyContent="center" alignItems="center" width="100%">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={
                  !formik.dirty ||
                  !formik.isValid ||
                  formik.isSubmitting ||
                  isSubmitting
                }
              >
                Next{' '}
                {isSubmitting && (
                  <CircularProgress
                    color="inherit"
                    style={{
                      width: '18px',
                      height: '18px',
                      marginLeft: '10px'
                    }}
                  />
                )}
              </Button>
            </Stack>
          </Stack>
        </form>
        <Snackbar
          open={showSnackBar}
          autoHideDuration={2500}
          onClose={handleSnackBarCloseButton}
        >
          <Alert
            onClose={handleSnackBarCloseButton}
            severity="error"
            variant="filled"
            sx={{ width: '100%' }}
          >
            {`Error: ${errorMessage} `}
          </Alert>
        </Snackbar>
      </>
    );
  }

  return null;
}
export default AddCameraInfo;

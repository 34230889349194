/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/function-component-definition */
import { Typography, Button, Stack } from '@mui/material';
import InstaCard from 'components/InstaCard';
import { useState, useEffect } from 'react';
import {
  getSubscriptionDetails,
  cancelFreeTrialSubscription,
  fetchCurrentPlanDetails
} from 'services/api/subscriptionApi';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import styled from 'styled-components';
import PlanFeaturesList from 'components/PlanFeaturesList';
import { useAppSelector } from 'store/hooks';
import { getAllDeviceWithSpaceId } from 'services/api/devicesApi';
import { Image } from 'styles';
import { getLocalStorageItem, isCardValid } from 'utils/commonFunctions';
import CancleSubscription from 'components/CancelSubscription';
import CustomerService from 'components/CustomerService';
import { TDeviceResponseType } from 'typings/deviceTypes';
import CameraImage from 'assets/camera.png';
import { CURRENCY } from 'configs/constants';
import VisaCard from 'assets/visa.png';
import MasterCard from 'assets/mastercard.png';
import InstaSpinner from 'components/InstaSpinner';
import { track } from '@amplitude/analytics-browser';
import { amplitudeEvents } from 'configs/amplitude';
import { SUBSCRIPTION_TYPE } from '../../typings/purchaseTypes';

const CameraDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 9px 0px;
`;
const ImageWrapper = styled.div`
  height: 48px;
  width: 48px;
  border-radius: 50%;
  display: flex;
  background: #ffffff;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;
const CameraDetails = styled.div`
  flex-grow: 2;
`;
const PaymentDetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FeatureListWrapper = styled.div`
  margin-top: 8px;
`;

interface TDeviceDetails {
  id: string;
  name: string;
  location: string;
}

function SubscriptionsDetails() {
  const [subscriptionData, setSubscriptionData] = useState<any>();
  const [cameraDetails, setCameraDetails] = useState<any>();
  const [showCancelFreeSubscriptionModal, setShowCancelFreeSubscriptionModal] =
    useState(false);
  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [planLevel, setPlanLevel] = useState<number>(1);

  const { id: subscriptionId } = useParams();
  const navigate = useNavigate();

  const spaceData = useAppSelector((state) => state.space.spaceData);
  const userId = getLocalStorageItem('userId');

  const onCancel = () => {
    if (subscriptionData?.trial) {
      setShowCancelFreeSubscriptionModal(true);
    } else {
      track(amplitudeEvents.SUBSCRIPTION_CANCEL, { userId });
      setShowCancelSubscriptionModal(true);
    }
  };

  const handlePopupCloseClick = () => {
    setShowCancelFreeSubscriptionModal(false);
  };

  const handleBackClick = () => {
    setShowCancelFreeSubscriptionModal(false);
  };

  const handleFreeTrialCancelSubscription = async () => {
    track(amplitudeEvents.TRIAL_CANCEL, { userId });
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const apiResponse = await cancelFreeTrialSubscription(
        subscriptionData?.id
      );
      setShowCancelFreeSubscriptionModal(false);
      if (apiResponse.status === 204) {
        navigate('/subscriptions', { replace: true });
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const handleCancelSubscriptionDoneButton = () => {
    setShowCancelSubscriptionModal(false);
  };

  useEffect(() => {
    if (spaceData.length) {
      const { id: spaceId } = spaceData[0];
      setIsLoading(true);

      Promise.all([getSubscriptionDetails(), getAllDeviceWithSpaceId(spaceId)])
        .then((res) => {
          const [subscriptionsData, devicesData] = res;
          const subscriptionPlanDetails = subscriptionsData.data.find(
            (planDetails) => planDetails.id === subscriptionId
          );
          const deviceDetails: TDeviceDetails[] = [];
          console.log('subscriptionPlanDetails:', subscriptionPlanDetails);
          const planSkuId = subscriptionPlanDetails?.sku_id || '';
          fetchCurrentPlanDetails(planSkuId).then((planInformation) => {
            setPlanLevel(planInformation.data.level);
          });
          subscriptionPlanDetails?.devices.forEach((deviceSubscriptionPlan) => {
            devicesData.data.items.forEach(
              (deviceDetail: TDeviceResponseType) => {
                if (deviceDetail.id === deviceSubscriptionPlan?.id) {
                  const info: TDeviceDetails = {
                    id: deviceDetail.id,
                    name: deviceDetail.name,
                    location: deviceDetail.location
                  };
                  deviceDetails.push(info);
                }
              }
            );
          });
          setSubscriptionData(subscriptionPlanDetails);

          setCameraDetails(deviceDetails);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log('err', err);
          setIsLoading(false);
        });
    }
  }, [spaceData]);

  if (isLoading) {
    return <InstaSpinner withWrapper />;
  }

  if (!isLoading && subscriptionData) {
    return (
      <>
        <Stack>
          <Typography variant="h4" sx={{ marginBottom: '8px' }}>
            {subscriptionData?.space_name}
          </Typography>
          <Typography variant="h3" sx={{ marginBottom: '8px' }}>
            CURRENT PLAN
          </Typography>
          <InstaCard>
            <div>
              <Typography variant="h2" sx={{ marginBottom: '8px' }}>
                {subscriptionData?.sku_name} -{' '}
                {subscriptionData?.subscription_cycle === 1
                  ? 'Monthly'
                  : 'Yearly'}
              </Typography>
              <Typography
                variant="Body1"
                sx={{
                  marginBottom: '8px'
                }}
                color="primary"
              >
                You are secured by our plan
              </Typography>
            </div>
            <FeatureListWrapper>
              <PlanFeaturesList
                featuresList={subscriptionData?.supported_features}
              />
            </FeatureListWrapper>
            <div>
              <Typography
                variant="Body1Medium"
                sx={{
                  marginBottom: '4px'
                }}
              >
                Subscribed for <strong>{subscriptionData?.space_name}</strong>
              </Typography>{' '}
              <br />
              <Typography
                variant="Body1Medium"
                sx={{
                  marginBottom: '4px'
                }}
              >
                Subscriber since{' '}
                <strong>
                  {dayjs(
                    subscriptionData?.start_date
                      ? subscriptionData.start_date
                      : subscriptionData?.trial_start_date
                  ).format('YYYY.MM.DD')}
                </strong>
              </Typography>
            </div>
          </InstaCard>
          <Typography variant="h3" sx={{ marginBottom: '8px' }}>
            LINKED DEVICES
          </Typography>
          <InstaCard>
            {cameraDetails.map((device: TDeviceDetails) => {
              return (
                <CameraDetailsWrapper key={device.id}>
                  <ImageWrapper>
                    <img src={CameraImage} alt="Camera" />
                  </ImageWrapper>
                  <CameraDetails>
                    <Typography variant="h4" sx={{ marginBottom: '5px' }}>
                      {device.name}
                    </Typography>

                    <Typography variant="Body1Medium" color="primary" mb={0.5}>
                      {device.location}
                    </Typography>
                  </CameraDetails>
                </CameraDetailsWrapper>
              );
            })}
          </InstaCard>
          {subscriptionData.subscription_type !== SUBSCRIPTION_TYPE.PREVIEW && (
            <>
              <Typography variant="h3" sx={{ marginBottom: '8px' }}>
                PAYMENT DETAILS
              </Typography>
              <InstaCard>
                <PaymentDetailsWrapper>
                  <Typography variant="h4" sx={{ marginBottom: '8px' }}>
                    Next Payment
                  </Typography>
                  {dayjs().isAfter(
                    dayjs(subscriptionData?.next_billing_date)
                  ) ? (
                    <Typography
                      variant="Body1"
                      sx={{ marginBottom: '8px' }}
                      color="secondary"
                    >
                      Overdue
                    </Typography>
                  ) : (
                    <Typography
                      variant="Body1"
                      sx={{ marginBottom: '8px' }}
                      color="primary"
                    >
                      {dayjs(subscriptionData?.next_billing_date).format(
                        'YYYY.MM.DD'
                      )}
                    </Typography>
                  )}
                </PaymentDetailsWrapper>
                <PaymentDetailsWrapper>
                  <Typography variant="h4" sx={{ margin: '16px 0px' }}>
                    Amount
                  </Typography>
                  <Typography variant="Body1" sx={{ margin: '16px 0px' }}>
                    {`${CURRENCY.USD} ${
                      subscriptionData.next_bill_amount / 100
                    }`}
                  </Typography>
                </PaymentDetailsWrapper>
                <PaymentDetailsWrapper>
                  <div>
                    <Typography variant="h4" sx={{ margin: '16px 0px' }}>
                      Method
                    </Typography>
                    {isCardValid(
                      subscriptionData.card_details.exp_month,
                      subscriptionData.card_details.exp_year
                    ) ? null : (
                      <Typography variant="Body1SemiBold" color="secondary">
                        Expired payment
                      </Typography>
                    )}
                  </div>
                  <Typography variant="Body1" sx={{ margin: '16px 0px' }}>
                    <Image
                      src={
                        subscriptionData.card_details.brand === 'visa'
                          ? VisaCard
                          : MasterCard
                      }
                      width={
                        subscriptionData.card_details.brand === 'visa'
                          ? '42'
                          : '32'
                      }
                      height={
                        subscriptionData.card_details.brand === 'visa'
                          ? '13'
                          : '24'
                      }
                    />
                    *{subscriptionData.card_details.last4}
                  </Typography>
                </PaymentDetailsWrapper>
              </InstaCard>
              <Button
                variant="contained"
                onClick={() => {
                  track(amplitudeEvents.SUBSCRIPTION_MODIFY, { userId });
                  navigate(`/subscription/${subscriptionData.id}/upgrade`, {
                    state: {
                      isFreeTrial: subscriptionData.trial,
                      isUpgrade: true
                    }
                  });
                }}
                disabled={
                  planLevel === 3 && subscriptionData.subscription_cycle === 12
                }
              >
                Modify {subscriptionData.trial ? 'Free trial' : 'Subscription'}
              </Button>
              <Button
                variant="text"
                color="secondary"
                onClick={onCancel}
                sx={{ textTransform: 'capitalize', marginTop: '8px' }}
                className="btn-no-focus"
              >
                Cancel {subscriptionData.trial ? 'Free trial' : 'Subscription'}
              </Button>
            </>
          )}
        </Stack>
        {subscriptionData.subscription_type !== SUBSCRIPTION_TYPE.PREVIEW && (
          <>
            <CancleSubscription
              isCancelModalOpen={showCancelFreeSubscriptionModal}
              onPopUpCloseClick={handlePopupCloseClick}
              onBackClick={handleBackClick}
              onFreeTrialCancelClick={handleFreeTrialCancelSubscription}
            />
            <CustomerService
              isCancelModalOpen={showCancelSubscriptionModal}
              onSubscriptionDoneClick={handleCancelSubscriptionDoneButton}
            />
          </>
        )}
      </>
    );
  }

  return null;
}

export default SubscriptionsDetails;

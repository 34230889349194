import { useState } from 'react';
import styled from '@emotion/styled';

import {
  Button,
  Typography,
  Stack,
  styled as styledMUI,
  Divider,
  useTheme
} from '@mui/material';
import './style.css';
import { CURRENCY } from 'configs/constants';
import RibbonIcon from 'assets/ribbon.svg';

import PlanFeaturesList from 'components/PlanFeaturesList';
import { TSubscriptionType, TPlanList } from 'typings';
import { round5 } from 'utils/commonFunctions';

const CarouselContainer = styledMUI('div')(() => ({
  // height: '60vh',
  width: '100%',
  margin: '0 auto',
  position: 'relative',
  perspective: '1000px',
  transformStyle: 'preserve-3d',
  marginTop: '60px',

  '& [type=radio]': {
    visibility: 'hidden'
  },

  '& label': {
    '& .Mui-Card': {
      height: '100%'
    }
  }
  //   '& input:checked + label': {
  //     transform: 'translate3d(0, 0, 0)'
  //   }
}));
const StyledLabel = styledMUI('label')(({ theme }) => ({
  margin: 'auto',
  width: '72%',
  // height: '100%',
  position: 'absolute',
  left: 0,
  right: 0,
  top: '40px',
  cursor: 'pointer',
  transition: 'transform 0.4s ease',

  borderRadius: '30px',
  backgroundColor: '#EDEDED',
  // position: 'relative',
  padding: '20px',
  // boxSizing: 'border-box',
  // boxShadow: 'none',
  // marginBottom: '20px',
  borderLeft: 'linear-gradient(45deg, black, transparent)',
  border: `1px solid ${theme.palette.background.orange}`
  // '&::before': {
  //   backgroundImage: 'linear-gradient(45deg, black, transparent)',
  //   borderRadius: '20px',
  //   padding: '1px',
  //   width: '100%',
  //   height: '100%',
  //   top: '-1px',
  //   left: '-1px',
  //   zIndex: '-1',
  //   position: 'absolute',
  //   content: '""'
  // },
}));

const PaymentContainer = styled.div`
  display: block;
`;

const GreyText = styled.span`
  color: 'rgba(0,0,0,0.4)';
`;

const InformationText = styled.p`
  color: #4389e1;
  margin: 0px;
`;

const InformationContainer = styled.div`
  padding-top: 30px;
  text-align: center;
`;

const ImgCntr = styled.img`
  position: absolute;
  top: 0px;
  left: 50%;
  height: 40px;
  transform: translate(-50%, -50%);
`;

// interface MyInputHTMLAttributes extends InputHTMLAttributes {
//     position: number
// }
// const StyledInput = styled('input')<MyInputHTMLAttributes>(({position, theme }) => ({
//     '& + label': {
//         transform: `translate3d(${position*50}%, 0, -100px)`
//     }
// }));

type TProps__PlansCarousel = {
  plansData: TPlanList[];
  currentPlan?: TSubscriptionType;
  allowSelection?: boolean;
  onPlanSelection?: (plan: TPlanList) => void;
  filterByLevel?: (plan: TPlanList) => number | boolean;
  isFreeTrial?: boolean;
};

function PlansCarousel(props: TProps__PlansCarousel) {
  const {
    plansData,
    currentPlan,
    allowSelection = false,
    onPlanSelection,
    filterByLevel,
    isFreeTrial
  } = props;

  const [selectedIndex, setSelectedIndex] = useState(0);
  const theme = useTheme();
  // for carousel buttons
  // const checkNext = () => {
  //   const labels = document.querySelectorAll('#slider label');
  //   const nextIndex =
  //     selectedIndex === labels.length - 1 ? 0 : selectedIndex + 1;
  //   setSelectedIndex(nextIndex);
  // };

  const check = (index: number) => setSelectedIndex(index);

  console.log('plansData -- ', plansData);

  return (
    <CarouselContainer id="slider">
      {plansData.map((item, index) => (
        <input
          type="radio"
          name="slider"
          key={item.id}
          id={`s${index + 1}`}
          checked={selectedIndex === index}
          //   position={index - selectedIndex}
        />
      ))}
      {plansData.map((plan, index) => (
        <StyledLabel
          htmlFor={`s${index + 1}`}
          id={`slide${index + 1}`}
          key={plan.id}
          onClick={() => check(index)}
        >
          {plan.level === 3 &&
            plan.subscription_cycle === 12 &&
            plan.id !== currentPlan?.sku_id && (
              <Typography
                variant="h3"
                color="primary"
                align="center"
                sx={{
                  textTransform: 'uppercase',
                  marginBottom: 4,
                  marginTop: -7,
                  position: 'absolute',
                  width: '200px',
                  top: 0,
                  left: '50%',
                  transform: 'translate(-50%, -50%)'
                }}
              >
                Most popular
              </Typography>
            )}

          {plan.level === 3 && plan.subscription_cycle === 12 && (
            <ImgCntr src={RibbonIcon} alt="Ribbon" />
          )}
          <Stack justifyContent="center" alignItems="center" mt={1.5}>
            <Typography variant="h3">{plan.name}</Typography>
            <PaymentContainer>
              <span style={{ fontSize: '32px', fontWeight: 500 }}>{`${
                CURRENCY[plan.prices[0].currency]
              }${round5(plan.prices[0].price / 52 / 100).toFixed(2)}`}</span>
              <GreyText>/week</GreyText>
            </PaymentContainer>

            <Stack direction="row" alignItems="center">
              <Typography
                variant="h5"
                sx={{
                  color: theme.palette.background.orange,
                  marginRight: 1,
                  fontSize: '18px'
                }}
              >{`${CURRENCY[plan.prices[0].currency]}${(
                plan.prices[0].price / 100
              ).toFixed(2)}`}</Typography>
              {/* <Typography variant="Body1SemiBold" color="secondary">{` save ${
                  CURRENCY[plan.prices[0].currency]
                }${
                  (plan.prices[0].original_price - plan.prices[0].price) / 100
                }`}</Typography> */}
              <GreyText>
                {plan.subscription_cycle === 1
                  ? 'billed monthly'
                  : 'billed annually'}
              </GreyText>
            </Stack>
          </Stack>
          <Divider sx={{ margin: '16px auto' }} />
          <PlanFeaturesList featuresList={plan.supported_features} />
          <InformationContainer>
            {isFreeTrial && (
              <InformationText>Free cancellation during trial</InformationText>
            )}
            <InformationText>Turn off auto renewal anytime</InformationText>
          </InformationContainer>
          {allowSelection && (
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                if (onPlanSelection) {
                  onPlanSelection(plan);
                }
              }}
              disabled={filterByLevel && !filterByLevel(plan)}
              sx={{ marginTop: '20px' }}
            >
              {isFreeTrial ? 'Try for free' : 'Select'}
            </Button>
          )}
        </StyledLabel>
      ))}
    </CarouselContainer>
  );
}

export default PlansCarousel;

/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Card, Divider, Stack, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import MasterCard from 'assets/mastercard.png';
import VisaCard from 'assets/visa.png';
import axios from 'axios';
import { CURRENCY } from 'configs/constants';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { endpoints } from 'services/api/endpoints';
import { GetPaymentMethods } from 'services/api/paymentsApi';
import {
  BuyUserSubscription,
  fetchCurrentPlanDetails,
  getSubscriptionDetails,
  UpgradeUserSubscription
} from 'services/api/subscriptionApi';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchAllSubscription } from 'store/reducers/subscriptionReducer';
import { Flex, Image } from 'styles';
import PaymentScreenDemo from 'views/PaymentScreen/PaymentScreen.demo';

import InstaCard from 'components/InstaCard';
import InstaSpinner from 'components/InstaSpinner';
import PaymentConfirmed from 'components/PaymentConfirmed';
import PlanFeaturesList from 'components/PlanFeaturesList';
import PopUp from 'components/PopUp';
import TrialActivation from 'components/TrialActivation';

import { PopupTypes } from 'typings/popUpTypes';

import { track } from '@amplitude/analytics-browser';
import Authenticate3DS from 'components/Authenticate3DSFlow';
import PaymentFailed from 'components/PaymentFailed';
import StripePaymentFailed from 'components/StripePaymentFailed';
import { amplitudeEvents } from 'configs/amplitude';
import {
  TDeviceResponseType,
  TPaymentMethods,
  TProps_PaymentOptions,
  TStripeErrorResponse
} from 'typings';
import { getLocalStorageItem } from 'utils/commonFunctions';

dayjs.extend(relativeTime);

export default function ReviewPurchase(props: TProps_PaymentOptions) {
  const location = useLocation();
  const navigate = useNavigate();
  const { planSelected, purchaseId, isUpgrade, isFreeTrial, deviceId } =
    Object.keys(props).length > 0 ? props : location.state;

  // debugger;
  const [purchaseStatus, setPurchaseStatus] = useState<boolean | null>(null);
  const [paymentOption, setPaymentOption] = useState<TPaymentMethods[]>([]);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessingPurchase, toggleIsProcessingPurchase] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  console.log('errorMessage ', errorMessage);
  const [paymentOptionSelected, setPaymentOptionSelected] =
    useState<string>('');
  //   const navigate = useNavigate();
  const [selectedSpaceDeviceData, setSelectedSpaceDeviceData] = useState<
    TDeviceResponseType[]
  >([]);
  const spaceData = useAppSelector((state) => state.space.spaceData);
  const subscriptionData = useAppSelector(
    (state) => state.subscription.subscriptionData[0]
  );
  const [pollingCount, setPollingCount] = useState(0);
  const [proRataPrice, setProRataPrice] = useState(null);
  const [showPaymentFailedPopUp, setShowPaymentFailedPopUp] = useState(false);
  const [isAllCallSettled, setIsAllCallSettled] = useState(false);
  const [triggerStripePayment, setTriggerStripePayment] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [showStripePaymentFailedPopUp, setShowStripePaymentFailedPopUp] =
    useState(false);
  const [stripeErrorResponse, setStripeErrorResponse] =
    useState<TStripeErrorResponse>({ code: '', message: '' });
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [paymentErrorMessage, setPaymentErrorMessage] = useState<string | null>(
    null
  );
  const userId = getLocalStorageItem('userId');

  console.log('proRataPrice', proRataPrice);
  console.log('triggerStripePayment', triggerStripePayment);

  const onPaymentFailedTryAgainClick = () => {
    setShowPaymentFailedPopUp(false);
  };

  const onStripePaymentFailedTryAgainClick = () => {
    setShowStripePaymentFailedPopUp(false);
  };
  const handleChange = (e: { target: { value: string } }) => {
    setPaymentOptionSelected(e.target.value);
  };

  const fetchAllDevices = () => {
    axios
      .get(endpoints.getAllDeviceBySpaceIdUrl(spaceData[0].id || ''))
      .then((res) => {
        setSelectedSpaceDeviceData(res.data.items);
      })
      .catch((err) => {
        console.log('err', err);
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  const fetchProRataDetails = () => {
    axios
      .get(endpoints.paymentOptions(purchaseId))
      .then((res) => {
        setProRataPrice(res.data.upgrade_price);
      })
      .catch((err) => {
        console.log('fetchProRataDetails ERR', err);
      });
  };

  useEffect(() => {
    fetchAllDevices();
    if (isUpgrade) {
      fetchProRataDetails();
    }
    setIsLoading(true);
    GetPaymentMethods()
      .then((res) => {
        setPaymentOption(res.data.payment_method || []);
        if (res.data?.payment_method?.length > 0) {
          setPaymentOptionSelected(res?.data?.payment_method[0]['id']);
        }
      })
      .catch((err) => console.log('err payment_methods', err))
      .finally(() => {
        setIsLoading(false);
        setIsAllCallSettled(true);
      });

    return () => {
      dispatch(fetchAllSubscription());
    };
  }, []);

  const authenticateDevice = () => {
    // debugger;

    axios
      .patch(endpoints.authenticateDevice(spaceData[0].id, deviceId), {
        pairing_status: 'Activated'
      })
      .then((res) => {
        console.log('authenticateDevice success', res);
      })
      .catch((err) => {
        console.log('authenticateDevice err->>', err);
      });
  };

  const addCameraToSubscription = async (subscriptionObj: any) => {
    const planSkuId = subscriptionObj?.sku_id || '';
    const planInformation = await fetchCurrentPlanDetails(planSkuId);
    const planLevel = planInformation.data.level;
    const deviceArray = selectedSpaceDeviceData.map((el) => el?.id);
    const device_ids = deviceArray;

    let payload = {};

    if (isUpgrade) {
      // If the user is trying to upgrade to Solo Yearly, no need to support the second device
      if (planLevel === 1) {
        payload = {
          device_ids: [deviceArray[0] || deviceId]
        };
      }
      // If the user is trying to upgrade to anything other than Solo plans, add the devices.
      if (planLevel > 1) {
        if (deviceId) {
          device_ids.push(deviceId);
        }
        payload = {
          device_ids
        };
      }
    } else if (planLevel > 1) {
      if (deviceId) {
        device_ids.push(deviceId);
      }
      payload = {
        device_ids
      };
    } else {
      payload = {
        device_ids: [deviceArray[0] || deviceId]
      };
    }

    let errorMsg = '';

    axios
      .put(endpoints.addDevice(subscriptionObj.id), payload)
      .then((res) => {
        console.log('addCameraToSubscription success', res);
        dispatch(fetchAllSubscription());
      })
      .catch((err) => {
        console.log('addCameraToSubscription err->>', err);
        errorMsg =
          'Sorry! We cannot add the device to your selected to your subscription plan';
        setErrorMessage(
          'Sorry! We cannot add the device to your selected to your subscription plan'
        );
        console.log('addCameraToSubscription', err);
      })
      .finally(() => {
        setPurchaseStatus(true);
        toggleIsProcessingPurchase(false);
      });
    console.log('errorMsg ', errorMsg);
    if (deviceId) {
      // debugger;
      authenticateDevice();
    }
  };

  const findSubscriptionByPurchaseId = (subscriptions: any) => {
    const found = subscriptions.filter((item: any) => item.id === purchaseId);
    if (found.length) {
      return found[0];
    }
    return false;
  };

  const polling = () => {
    setTimeout(() => {
      getSubscriptionDetails()
        .then((res) => {
          //   debugger;

          const found = findSubscriptionByPurchaseId(res.data);
          //   debugger;

          if (found && found.id === purchaseId) {
            // debugger;
            addCameraToSubscription(found);
          } else {
            // debugger;
            console.log('pollingCount', pollingCount, Date.now());
            setPollingCount((prevState) => {
              if (prevState < 5) {
                polling();
              } else {
                setPurchaseStatus(false);
                toggleIsProcessingPurchase(false);
              }
              return prevState + 1;
            });
          }
        })
        .catch((err) => {
          //   debugger;
          toggleIsProcessingPurchase(false);
          setPurchaseStatus(false);
          console.log('getSubscriptionDetails ERROR', err);
        });
    }, 1000);
  };

  const submitPurchaseRequest = (value?: string | null) => {
    toggleIsProcessingPurchase(true);
    const payload = {
      purchase_id: purchaseId as string,
      stripe_payment_method: value || paymentOptionSelected
    };

    if (isFreeTrial) {
      track(amplitudeEvents.TRIAL_CONFIRMED, { userId });
    } else {
      track(amplitudeEvents.SUBSCRIPTION_BUY_NOW, {
        userId,
        level: planSelected.level,
        cycle: planSelected.subscription_cycle
      });
    }
    const startPurchase = isUpgrade
      ? UpgradeUserSubscription
      : BuyUserSubscription;

    startPurchase(payload)
      .then((res) => {
        console.log('hdwejk', res);
        if (res.data.status === 'requires_action') {
          setTriggerStripePayment(true);
          setClientSecret(res.data.client_secret);
        }
        if (res.data.status === 'requires_payment_method') {
          setTriggerStripePayment(true);
          setClientSecret(res.data.client_secret);
          console.log('reviewPurchase:: ', res.data.status);
        }
        if (res.data.status === 'succeeded' || res.data.status === '') {
          polling();
        } else {
          setPurchaseStatus(false);
          toggleIsProcessingPurchase(false);
        }
      })
      .catch((err) => {
        // debugger;
        setPurchaseStatus(false);
        toggleIsProcessingPurchase(false);
        if (err.response.status === 400) {
          setPaymentErrorMessage(err?.response?.data?.message);
        }
        setShowPaymentFailedPopUp(true);

        console.log('err submitPurchaseRequest', err);
      });
  };

  useEffect(() => {
    console.log('detech change', planSelected);
  }, [planSelected?.id]);

  const { name, prices } = planSelected || {};
  const { currency, price } = (Array.isArray(prices) && prices[0]) || {
    currency: 'USD'
  };

  if (subscriptionData === undefined) {
    <Flex style={{ position: 'fixed', top: '50%', left: '50%' }}>
      <InstaSpinner />
    </Flex>;
  }

  let refDate;
  let today;
  let diff;
  if (subscriptionData) {
    refDate = subscriptionData?.trial
      ? dayjs(subscriptionData.trial_start_date)
      : dayjs(subscriptionData.pay_date);
    today = dayjs(new Date());
    diff = today.diff(dayjs(refDate), 'day', true);
    diff = diff < 1 ? Math.ceil(diff) : Math.floor(diff);
  } else {
    today = dayjs(new Date());
    refDate = '';
    diff = '';
  }
  console.log('diff ', diff);

  if (isLoading) {
    return <InstaSpinner withWrapper />;
  }

  if (!isLoading) {
    return (
      <div>
        <Typography
          variant="h2"
          textAlign="center"
          fontWeight="600"
          sx={{
            textTransform: 'capitalize',
            marginTop: (theme) => theme.spacing(2),
            marginBottom: (theme) => theme.spacing(1.5)
          }}
        >
          Review your plan
        </Typography>
        <Box sx={{ marginTop: '20px' }}>
          {!isFreeTrial && (
            <InstaCard>
              <Typography variant="h2" padding="0 10px 16px 10px">
                {name}
                {planSelected.subscription_cycle === 1
                  ? ' - Monthly '
                  : ' - Yearly '}
              </Typography>

              <Box sx={{ padding: '16px 10px' }}>
                <PlanFeaturesList
                  featuresList={planSelected.supported_features}
                />
              </Box>
              <Stack
                justifyContent="space-between"
                direction="row"
                padding="16px 10px 0 10px"
              >
                {!isUpgrade && (
                  <>
                    <Typography variant="h3">Total</Typography>

                    <Stack alignItems="flex-end">
                      <Typography variant="h3">
                        {CURRENCY[currency]}
                        {Number(price) / 100}
                      </Typography>
                    </Stack>
                  </>
                )}
                {isUpgrade && (
                  <>
                    <Typography variant="h3">
                      Prorated adjusted total
                    </Typography>

                    <Stack alignItems="flex-end">
                      <Typography variant="h3">
                        {CURRENCY[currency]}
                        {Number(proRataPrice) / 100}
                      </Typography>
                    </Stack>
                  </>
                )}
              </Stack>
            </InstaCard>
          )}

          {isFreeTrial && (
            <>
              <Box>
                <Typography
                  variant="h1"
                  textAlign="center"
                  sx={{
                    textTransform: 'capitalize',
                    marginTop: (theme) => theme.spacing(2),
                    marginBottom: (theme) => theme.spacing(1.5)
                  }}
                >
                  You Are Almost Done
                </Typography>
                <Typography
                  mt={1.5}
                  mb={2}
                  color="primary"
                  sx={{ textAlign: 'center' }}
                >
                  We will notify you by email a day before your free trial
                  converts to a subscription.
                </Typography>
              </Box>
              <Card
                style={{
                  border: '0.5px solid #F35D1D',
                  borderRadius: '20px',
                  padding: '20px',
                  background: '#EDEDED',
                  boxShadow: 'none'
                }}
              >
                <Box textAlign="center" sx={{ margin: '16px' }}>
                  <Typography
                    variant="h3"
                    padding="0 10px 16px 10px"
                    textAlign="center"
                    fontWeight="500"
                  >
                    {name}
                  </Typography>
                  <Typography variant="h1" color="secondary">
                    {CURRENCY[currency]}
                    {0}
                  </Typography>
                  <Typography variant="Body1Medium" color="#00000066">
                    First 15 days
                  </Typography>
                </Box>
                <Divider />
                <Typography
                  variant="h4"
                  paddingTop="12px"
                  fontWeight="500"
                  lineHeight="1.5"
                >
                  Cancel anytime before free trial ends
                  <br /> Turn off auto renewal at anytime
                </Typography>
              </Card>
            </>
          )}
        </Box>

        {paymentOption.length > 0 && (
          <Flex style={{ justifyContent: 'space-between' }}>
            <Typography
              variant="h3"
              sx={{ textTransform: 'uppercase', mt: '30px', mb: '16px' }}
            >
              PAYMENT METHOD
            </Typography>

            <Button
              variant="text"
              sx={{ padding: '0px' }}
              onClick={() => navigate('/billings/new')}
            >
              Update
            </Button>
          </Flex>
        )}
        {paymentOption.length > 0 && (
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={paymentOptionSelected}
            onChange={handleChange}
          >
            <InstaCard>
              {paymentOption.map(
                (el: {
                  id: string;
                  card: {
                    brand: string;
                    last4: string;
                    exp_month: number;
                    exp_year: number;
                  };
                }) => {
                  const {
                    id,
                    card: { last4, brand, exp_month, exp_year }
                  } = el;
                  return (
                    <FormControlLabel
                      key={id}
                      value={id}
                      control={<Radio />}
                      labelPlacement="start"
                      sx={{
                        justifyContent: 'space-between',
                        width: '100%',
                        margin: 0
                      }}
                      label={
                        <Stack direction="row" alignItems="center" gap={3}>
                          <Image
                            src={brand === 'visa' ? VisaCard : MasterCard}
                            width={brand === 'visa' ? '52' : '40'}
                            height={brand === 'visa' ? '16' : '30'}
                          />
                          <Stack gap={1.5}>
                            <Typography variant="h4">
                              **** **** **** {last4}
                            </Typography>
                            <Typography
                              variant="Body1Medium"
                              color="text.shadedText"
                            >{`Expiry date ${exp_month}/${exp_year}`}</Typography>
                          </Stack>
                        </Stack>
                      }
                    />
                  );
                }
              )}
            </InstaCard>
          </RadioGroup>
        )}
        {console.log('paymentOptionSelected ', paymentOptionSelected)}
        {(paymentOptionSelected === 'ADDCARD' || paymentOption.length === 0) &&
        !isLoading &&
        isAllCallSettled ? (
          <>
            <Typography
              variant="h3"
              sx={{ textTransform: 'uppercase', mt: '30px', mb: '16px' }}
            >
              card details
            </Typography>
            <PaymentScreenDemo
              isUpgrade={isUpgrade}
              isFreeTrial={isFreeTrial}
              amount={price}
              submitPurchaseRequest={submitPurchaseRequest}
            />
          </>
        ) : (
          <Button
            disabled={isLoading || isApiLoading}
            variant="contained"
            fullWidth
            onClick={() => submitPurchaseRequest(null)}
          >
            {isFreeTrial && !isUpgrade && 'Start Free Trial'}
            {isFreeTrial && isUpgrade && 'Upgrade Free Trial'}
            {!isFreeTrial &&
              !isUpgrade &&
              `Pay ${CURRENCY[currency]}${price && (price / 100).toFixed(2)}`}
            {!isFreeTrial &&
              isUpgrade &&
              `Pay ${CURRENCY[currency]}${
                proRataPrice && (proRataPrice / 100).toFixed(2)
              }`}
          </Button>
        )}
        {isProcessingPurchase && (
          <PopUp isOpen type={PopupTypes.TYPE2}>
            <Stack justifyContent="center" alignItems="center">
              <InstaSpinner />
              <Typography variant="h3" mt={4} textAlign="center">
                Please wait...
              </Typography>
            </Stack>
          </PopUp>
        )}
        {purchaseStatus === true && isFreeTrial && (
          <TrialActivation isUpgrade={isUpgrade} />
        )}
        {purchaseStatus === true && !isFreeTrial && (
          <PaymentConfirmed
            planName={planSelected.name}
            planCycle={planSelected.subscription_cycle}
          />
        )}
        {showPaymentFailedPopUp && (
          <PaymentFailed
            showPaymentFailedPopUp={showPaymentFailedPopUp}
            handlePaymentFailedTryAgainClick={onPaymentFailedTryAgainClick}
            paymentErrorMessage={paymentErrorMessage}
          />
        )}
        {showStripePaymentFailedPopUp && (
          <StripePaymentFailed
            showStripePaymentFailedPopUp={showStripePaymentFailedPopUp}
            handlePaymentFailedTryAgainClick={
              onStripePaymentFailedTryAgainClick
            }
            code={stripeErrorResponse.code}
            message={stripeErrorResponse.message}
          />
        )}
        {triggerStripePayment && (
          <Authenticate3DS
            paymentOptionSelected={paymentOptionSelected}
            clientSecret={clientSecret}
            polling={polling}
            setShowStripePaymentFailedPopUp={setShowStripePaymentFailedPopUp}
            setStripeErrorResponse={setStripeErrorResponse}
            setIsApiLoading={setIsApiLoading}
            setTriggerStripePayment={setTriggerStripePayment}
          />
        )}
      </div>
    );
  }
  return null;
}
